import { useEffect, useState } from "react";
import { useCart } from "./cart";

export function useCartId() {
  const cart = useCart();
  const [cartId, setCartId] = useState<string>();
  useEffect(() => {
    if (!cart) return;
    setCartId(cart.id);
  }, [cart]);

  return cartId;
}
