import Link from "next/link";
import Image from "next/image";
import React from "react";

export default function StoreFooter({
  brand_logo,
  menu_link_color,
}: {
  brand_logo: string;
  menu_link_color: string;
}) {
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200">
          <div className="pt-16 pb-20">
            <div className="md:flex md:justify-center">
              <Link href={"/"}>
                <Image
                  priority={true}
                  width={450}
                  height={100}
                  src={brand_logo}
                  alt=""
                  className="h-8 w-auto"
                />
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{ color: menu_link_color }}
          className={`py-10 md:flex md:items-center md:justify-between`}
        >
          <div className="flex gap-4">
            <p className="text-sm">
              &copy; {new Date().getFullYear()} All Rights Reserved - A Property
              of My Comedy Tickets Inc.
            </p>
            <a className={"text-sm"} href={"/terms-of-use.html"}>
              Terms of Use
            </a>
            <a className={"text-sm"} href={"/privacy-policy.html"}>
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
