import { usePosLocation } from "../hooks/pos-context";
import { FaStopCircle } from "react-icons/fa";
import { useAppDispatch } from "../store/store";
import { clearPosLocation, initializePos } from "../store/pos-slice";
import { useEffect } from "react";

export default function PosActivatedMessage() {
  const posLocation = usePosLocation();
  const dispatch = useAppDispatch();
  const removePosContext = () => {
    dispatch(clearPosLocation());
  };

  useEffect(() => {
    dispatch(initializePos());
  }, [dispatch]);

  return (
    <>
      {posLocation && (
        <div className={"bg-yellow-400 w-full flex gap-x-4 justify-center"}>
          <div className="text-black text-xl p-4 text-center">
            In-Person Payment Mode for {posLocation.account.name}
          </div>
          <button className={"text-black font-bold"} onClick={removePosContext}>
            <FaStopCircle className={"text-xl text-red-500 hover:text-black"} />{" "}
          </button>
        </div>
      )}
    </>
  );
}
