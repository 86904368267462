import { SeatNumberAndId } from "@utils/seating-map/seat";

export default function SeatsSelectedStatus({
  seatsSelected,
  seatSelectionsRequired,
}: {
  seatsSelected: SeatNumberAndId[];
  seatSelectionsRequired: number;
}) {
  return (
    <>
      <div>
        Selected {seatsSelected.length} of {seatSelectionsRequired} seats .
      </div>
      <div className={"h-10"}>
        {seatsSelected.map((s) => (
          <span key={s.seatId} className={"badge badge-secondary mr-2"}>
            {s.seatNumber}
          </span>
        ))}
      </div>
    </>
  );
}
