import { useSearchParams } from "next/navigation";
import { useMedusa } from "@hooks/medusa";
import { useEffect } from "react";
import { useCartId } from "@hooks/cart-id";

export default function ReferrerDetector() {
  const searchParams = useSearchParams();
  const ref = searchParams.get("ref");
  const { medusa } = useMedusa();
  const cartId = useCartId();

  useEffect(() => {
    if (!cartId || !medusa || !ref) return;
    medusa.carts.update(cartId, {
      context: { ref },
    });
  }, [cartId, medusa, ref]);

  return <></>;
}
