import { useSearchParams } from "next/navigation";
import { useMedusa } from "../hooks/medusa";
import { useEffect } from "react";
import { useCartId } from "../hooks/cart-id";

export default function UtmCampaignDetector() {
  const searchParams = useSearchParams();
  const utmCampaign = searchParams.get("utm_campaign");
  const { medusa } = useMedusa();
  const cartId = useCartId();

  useEffect(() => {
    if (!cartId || !medusa || !utmCampaign) return;
    medusa.carts.update(cartId, {
      context: { utmCampaign },
    });
  }, [cartId, medusa, utmCampaign]);

  return <></>;
}
