import Link from "next/link";

import React from "react";

export default function LoginButton() {
  return (
    <Link
      href={`/login`}
      className="block px-4 py-2 text-sm md:text-base a-customer-sign-in-link hover:text-black"
    >
      Login
    </Link>
  );
}
