import { ShoppingCartIcon } from "@heroicons/react/outline";
import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import { selectCartState } from "../store/cart-slice";
import { Cart } from "@medusajs/medusa";
import { getItemCount } from "../utils/cart";

export default function CartButton() {
  const cart = useSelector(selectCartState) as Cart;

  return (
    <Link href="/store/cart" className="group -m-2 p-2 flex items-center">
      <ShoppingCartIcon
        className="h-6 md:h-8 group-hover:text-black"
        aria-hidden="true"
      />
      <span className="ml-2 text-sm md:text-base font-medium group-hover:text-black">
        {getItemCount(cart)}
      </span>
      <span className="sr-only">items in cart, view bag</span>
    </Link>
  );
}
