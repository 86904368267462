import { useSelector } from "react-redux";
import { selectErrors, selectSuccess } from "../store/messages-slice";
import { Message } from "./message";

export default function Messages() {
  const errors = useSelector(selectErrors);
  const success = useSelector(selectSuccess);
  if (!errors && !success) return <></>;
  return (
    <>
      {(errors.length !== 0 || success.length !== 0) && (
        <div className={"flex flex-col gap-y-2 my-2"}>
          {errors &&
            errors.map((m, i) => (
              <Message key={i} message={m} type={"error"} />
            ))}
          {success &&
            success.map((m, i) => (
              <Message key={i} message={m} type={"success"} />
            ))}
        </div>
      )}
    </>
  );
}
