"use client";
import React from "react";
import { useCart } from "@hooks/cart";

export default function CancelCart({
  className,
  onCancel,
}: {
  className?: string;
  onCancel?: () => void;
}) {
  const cart = useCart();

  if (!cart) return <></>;
  return (
    <button
      className={className ? className : "text-xs"}
      type={"button"}
      onClick={() => {
        localStorage.removeItem("cartId");
        if (onCancel) {
          onCancel();
        }
        if (cart.items?.length) {
          window.location.replace(`/e/${cart.items[0].variant.product.handle}`);
        } else {
          window.location.replace("/");
        }
      }}
    >
      Cancel and Start Over
    </button>
  );
}
