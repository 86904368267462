"use client";
import StoreLayout from "@components/layout/store-layout";
import React, { useEffect } from "react";
import { setBrand } from "@store/site-slice";
import { fetchCart } from "@store/cart-slice";
import { useAppDispatch } from "@store/store";
import { useGetBrandByDomainQuery } from "@store/adsSlice";
import { initializeLocation } from "@utils/product-page-utils";
import { Message } from "@components/message";

export default function Layout({ children, params }: any) {
  const domain = decodeURIComponent(params.domain);
  const { data: brand_info } = useGetBrandByDomainQuery(domain);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!brand_info || brand_info.status !== "ok") return;
    const load = async () => {
      dispatch(setBrand(brand_info.data));
    };
    load();
  }, [brand_info]);

  if (!brand_info) return <></>;
  if (brand_info.status !== "ok")
    return (
      <div>
        <Message
          type={"warning"}
          title={"Brand Not Found"}
          message={
            "This brand was not found. Please ensure a domain is configured or double check whether the website URL is correctly entered."
          }
        />
      </div>
    );
  return (
    <StoreLayout>
      <div className="max-w-2xl mx-auto pb-24 pt-4 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {children}
      </div>
    </StoreLayout>
  );
}
