import { Menu } from "@headlessui/react";
import { useStoreUser } from "../hooks/store-user";
import Image from "next/image";

export default function StoreUserMenu() {
  const { picture } = useStoreUser();
  return (
    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full">
      <span className="sr-only">Open user menu</span>

      {picture && (
        <Image
          height={100}
          width={100}
          className="h-10 w-10 rounded-full"
          src={picture}
          alt=""
        />
      )}
    </Menu.Button>
  );
}
