import Link from "next/link";
import { classNames } from "../models/models";
import { Menu } from "@headlessui/react";
import React from "react";

export default function MyOrdersButton() {
  return (
    <Menu.Item key="myOrders">
      {({ active }) => (
        <Link
          href={"/store/account/orders"}
          className={classNames(
            active ? "bg-gray-100" : "",
            "block px-4 py-2 text-md text-gray-700",
          )}
        >
          Orders
        </Link>
      )}
    </Menu.Item>
  );
}
